<template>
  <div class="referrals">
    <h2>Реферальная программа</h2>
    <p>
      Пригласите друзей в наше приложение и получите бонусы за каждого
      приглашенного!
    </p>
    <button v-if="referralLink" @click="openShareDialog">
      Отправить реферальную ссылку
    </button>
    <p v-else>Загрузка ссылки...</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      referralLink: "", // Храним реферальную ссылку, полученную с сервера
      initData: null, // Храним данные инициализации Telegram WebApp
    };
  },
  mounted() {
	  this.initData = Telegram.WebApp.initDataUnsafe;
	  const urlParams = new URLSearchParams(window.location.search);
	  const referralCode = urlParams.get('ref');

	  if (referralCode) {
		this.handleReferral(referralCode);
	  }
	  this.fetchReferralLink();
	},
  methods: {
    // Открытие диалога отправки реферальной ссылки
    openShareDialog() {
      const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(this.referralLink)}&text=${encodeURIComponent(
        `Присоединяйся к нам с моей реферальной ссылкой: ${this.referralLink}`
      )}`;
      Telegram.WebApp.openTelegramLink(shareUrl);
    },

    // Получение реферальной ссылки с бэкенда
    async fetchReferralLink() {
      const userId = this.initData?.user?.id;

      if (!userId) {
        console.error("Ошибка: данные пользователя не получены.");
        return;
      }

      try {
        const response = await fetch("https://api.crytech.pro/get_referral_link", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ chat_id: userId }), // Изменено с chatId на chat_id
        });

        const data = await response.json();

        if (data.success && data.referral_link) {
          this.referralLink = data.referral_link; // Изменено для совпадения с ключом в ответе
        } else {
          console.error("Ошибка при получении реферальной ссылки:", data.message);
        }
      } catch (error) {
        console.error("Ошибка при запросе к серверу:", error);
      }
    },

    // Обработка реферального кода (если передан)
    // В handleReferral
    handleReferral(referralCode) {
		try {
		  // Декодируем и обрабатываем реферальный код
		  const decoded = atob(referralCode);
		  const referrerChatId = decoded.split('-')[0]; // Предполагая, что код включает chat_id

		  if (referrerChatId) {
			this.sendReferralData(referrerChatId);
		  } else {
			console.error("Invalid referrerChatId");
		  }
		} catch (error) {
		  console.error("Ошибка при декодировании referralCode:", error);
		}
	  },

    // Отправка данных о реферальной связи
    async sendReferralData(referrerChatId) {
		const userId = this.initData?.user?.id;
		if (!userId) {
		  console.error("Ошибка: данные пользователя не получены.");
		  return;
		}

		try {
		  const response = await fetch("https://api.crytech.pro/invite_user", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ chatId: userId, referrerChatId }),
		  });

		  const data = await response.json();
		  if (data.success) {
			console.log("Пользователь успешно приглашен и монеты начислены");
		  } else {
			console.log("Ошибка при обработке приглашения:", data.message);
		  }
		} catch (error) {
		  console.error("Ошибка при отправке данных на сервер:", error);
		}
	  },
  },
};
</script>




<style scoped>
.referrals {
  text-align: center;
  margin-top: 20px;
}

.referrals button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.referrals button:hover {
  background-color: #0056b3;
}

.referral-link {
  margin-top: 20px;
  text-align: left;
}

.referral-link input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
  background-color: #f9f9f9;
}
</style>
